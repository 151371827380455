body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../public/images/texture_069.jpg');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.nav-link {
  color: white;
  background-color: #800000;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover {
  background-color: white;
  color: #b30000;
}

.nav-item.active .nav-link {
  background-color: white;
  color: #b30000;
}

img{
  flex-wrap: wrap;
}



.menu-button {
  background-color: white;
  color: #b30000;
  border: 2px solid white;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.menu-button:focus {
  outline: none;
}

.nav .nav-item.active .nav-link {
  background-color: #800000;
  color: white;
}

@media (max-width: 767.98px) {
  .nav {
      display: block;
  }
  .nav .nav-item {
      margin: 0;
  }
  .nav .nav-link {
      display: block;
      width: 100%;
      text-align: center;
  }
}
